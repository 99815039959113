@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 300;
  src: url("~assets/fonts/Sofia Pro Light Az.otf") format("opentype");
}
@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 400;
  src: url("~assets/fonts/Sofia Pro Regular Az.otf") format("opentype");
}
@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 500;
  src: url("~assets/fonts/Sofia Pro Medium Az.otf") format("opentype");
}
@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 600;
  src: url("~assets/fonts/Sofia Pro Semi Bold Az.otf") format("opentype");
}
@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 700;
  src: url("~assets/fonts/Sofia Pro Bold Az.otf") format("opentype");
}
body {
  background: #e2e2e2;
  font-family: "Sofia Pro";
  color: #183b4e;
  font-size: 25px;
}
@media (min-width: 2500px) {
  body {
    font-size: 35px;
  }
}
@media (min-width: 3000px) {
  body {
    font-size: 40px;
  }
}
@media (min-width: 3500px) {
  body {
    font-size: 55px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  line-height: 1;
}

#q-app .text-500 {
  font-weight: 500;
}
#q-app .text-600 {
  font-weight: 600;
}
#q-app .text-300 {
  font-weight: 300;
}
#q-app .flex-wrap {
  flex-wrap: wrap;
}
#q-app .q-btn {
  font-size: 0.7em;
}
#q-app .q-field {
  zoom: 1.2;
}
@media (min-width: 2500px) {
  #q-app .q-field {
    zoom: 2;
  }
}

.text-body2 {
  font-size: 0.8em;
  line-height: 1;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  will-change: opacity;
}